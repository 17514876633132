import React from 'react';
import './index.css';

function Load() {
    return (
        <div className="container-load">
            <span className="tiktok-loader"></span>
        </div>
    );
}

export default Load;
