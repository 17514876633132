const images = {
    avatarDefault: require('../../assets/images/avatarDefault.png'),
    logo: require('../../assets/images/cropped-logo_ktx.png'),
    roomImg: require('../../assets/images/room.png'),
    avatarNam: require('../../assets/images/nam.png'),
    avatarNu: require('../../assets/images/nu.png'),
    ktx1: require('../../assets/images/ktx1.jpg'),
    ktx2: require('../../assets/images/ktx2.jpg'),
    ktx3: require('../../assets/images/ktx3.jpg'),
    ktxFooter: require('../../assets/images/ktx-footer.png'),
    banner: require('../../assets/images/Welcome.png'),
};

export default images;
